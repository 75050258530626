import { Col, Space, DatePicker,Row } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

export default function SelectDate({
    dark_Theme,
    start_date,
    end_date,
    handleRangePickerChange,
    showTradeButtons
}) {
  const location = useLocation();
  const isVolSurfacePage = location.pathname.includes("vol-surface");
  const hideDateFilterOnProdForVolSurface = process.env.REACT_APP_NODE_ENV === 'production' && isVolSurfacePage;
  return (
    <>
      <Col span={24}>
        <Space>{showTradeButtons}</Space>
      </Col>
      <Col span={24}>
        {(!hideDateFilterOnProdForVolSurface) && (
          <Row size={"middle"} className={`${dark_Theme && "date-picker-dark"}`}>
            <Col span={3}>
              <span style={{ whiteSpace: "nowrap" }}>Data to include:</span>
            </Col>
            <Col>
              <RangePicker
                onChange={handleRangePickerChange}
                className={start_date && "filled--data_rangePicker__background"}
                popupClassName={dark_Theme && "date-picker-dropdown-dark"}
                value={start_date ? [moment(start_date), moment(end_date)] : ""}
              />
            </Col>
        </Row>
      )}
        
      </Col>
    </>
  );
}
