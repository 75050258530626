/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
import { Row, Col } from "antd";

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "ag-grid-enterprise";
import { GlobalContext } from "lib/contexts/GlobalContext";
import "./gridStyles.css";
import {
  SCROLL_POSITION,
  VOL_SURFACE_Control_COLS,
} from "lib/constant/VolSurface";
import {
  getControlTableData,
  getGraphsData,
  updateOnEditCell,
} from "api/VolSurface/VolSurface";
import {
  updateGraph,
  updateVolSurfaceBodyData,
  updateVolSurfaceData,
  updateVolSurfaceEstimateData,
} from "lib/helpers/VulSurface/VulSurface";
import { useVolSurfaceControlTable } from "lib/contexts/VolSurfaceControlTableContext";
import { getRowClass, getRowStyle } from "lib/constant/AgGridCommonStyle";
import { VolSurfaceContext } from "lib/contexts/VolSurfaceContext";
import AgGridMonthEstimatesTable from "./AgGridMonthEstimatesTable";
import { setDataToLocalStorage } from "lib/helpers/GeneralFuncHelpers";
import useVolSurfaceWebSocket from "lib/hooks/useVolSurfaceWebSocket";

export default function AgGridControlTable({ scrollableRef }) {
  const [gridHeight, setGridHeight] = useState("auto");
  const gridRef = useRef();
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const {
    setIsCellEditing,
    setVolSurfaceData: setRowsData,
    setRowEditingData,
    isLoading,
    selectedFilters,
    volSurfaceTablesData: rowsData,
    setVolCalcTable,
    setTableHeight,
    volCalcTable,
    setScrollPosition,
    setRiskFree,
  } = useVolSurfaceControlTable();

  const {
    state: { volSurfaceData },
    setVolSurfaceData,
  } = useContext(VolSurfaceContext);

  const columnDefs = useMemo(() => VOL_SURFACE_Control_COLS, []);

  const onCellEditingStopped = useCallback(
    (event) => {
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      if (+event.oldValue === +event.newValue) return;

      const { prepareBody, bodyForUnderlying } = updateVolSurfaceBodyData(
        event,
        selectedFilters
      );
      if (!selectedFilters?.comparison_surface_id) {
        delete prepareBody.comparison_surface_id;
        delete bodyForUnderlying.comparison_surface_id;
      }
      setIsCellEditing(true);
      setScrollPosition(scrollableRef.current.scrollTop);
      setDataToLocalStorage(SCROLL_POSITION, scrollableRef.current.scrollTop);
      updateOnEditCell(
        event.colDef.headerName === "Tenor" ? bodyForUnderlying : prepareBody
      )
        .then((res) => {
          if (res.status !== 200) {
            revertCellValue(event.node, event.oldValue);
          } else {
            const updatedData = updateVolSurfaceData(
              res.data.data.control_table_rows,
              rowsData
            );
            setRowsData(updatedData);
            if (res.data.data.graphs_data) {
              updateGraph(res, volSurfaceData);
              setVolSurfaceData(volSurfaceData);
            }
            const volCalcTableUpdatedData = updateVolSurfaceEstimateData(
              res.data.data.vol_calc_table_rows,
              volCalcTable
            );
            setVolCalcTable(volCalcTableUpdatedData);

            let refreshParams = { force: true };
            gridRef.current.api.refreshCells(refreshParams);
            setIsCellEditing(false);
            setRowEditingData(null);
            if (event.colDef.headerName === "Tenor") {
              getGraphsData({
                product_contract_style_id: selectedFilters?.product_id,
                risk_free: selectedFilters?.risk_free || 0,
                comparison_surface_id: selectedFilters?.comparison_surface_id,
              }).then((res) => {
                const response = res.data.data;
                setVolSurfaceData(response);
              });
            }
          }
        })
        .catch((e) => {
          revertCellValue(event.node, event.oldValue);

          console.log("Error while setting data");
          setIsCellEditing(false);
          setRowEditingData(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rowsData,
      selectedFilters,
      setIsCellEditing,
      setRowEditingData,
      volSurfaceData,
    ]
  );

  const revertCellValue = (node, value) => {
    node.setDataValue(columnDefs[0].field, value);
  };
  const getRowId = (params) => {
    return params.data.id;
  };

  function getTableDate() {
    if (!selectedFilters?.product_id) return;
    getControlTableData({
      product_contract_style_id: selectedFilters?.product_id,
      risk_free: selectedFilters?.risk_free || 0,
      comparison_surface_id: selectedFilters?.comparison_surface_id,
    })
      .then((res) => {
        setRowsData(res.data.data.main_control_table);
        setVolCalcTable(res.data.data.vol_calc_table);
        setRiskFree(res.data.data.risk_free);
      })
      .finally(() => {});
  }
  //Get Control Table Data
  useEffect(() => {
    if (!selectedFilters?.product_id) return;
    getTableDate();
  }, [
    selectedFilters?.comparison_surface_id,
    selectedFilters?.product_id,
    selectedFilters?.risk_free,
    selectedFilters?.live_surface_id,
    setRowsData,
    setVolCalcTable,
    setRiskFree,
  ]);

  //websocket call for control table
  useVolSurfaceWebSocket(selectedFilters, () => {
    if (!selectedFilters?.product_id) return;
    getTableDate();
    getGraphsData({
      product_contract_style_id: selectedFilters?.product_id,
      risk_free: selectedFilters?.risk_free || 0,
      comparison_surface_id: selectedFilters?.comparison_surface_id,
    }).then((res) => {
      const response = res.data.data;

      setVolSurfaceData(response);
    });
  });

  //handle grid height
  useEffect(() => {
    if (rowsData?.length === 0) return;
    const numberOfRowData = rowsData?.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
    setTableHeight(`${calculatedHeight}px`);
  }, [rowsData?.length, setTableHeight]);

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
  };

  //change color of cell when editing
  gridOptions.onCellEditingStarted = function (event) {
    var editor = event.api.getCellEditorInstances({
      rowIndex: event.rowIndex,
      column: event.column,
    });
    if (editor && editor.length > 0) {
      editor[0].getGui().style.color = dark_Theme ? "white" : "black";
    }
  };

  useEffect(() => {});

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    gridRef.current.api.showLoadingOverlay();
  };

  if (isLoading || !selectedFilters?.product_id ) return null;

  return (
    <Col
      xs={24}
      xxl={15}
      className={`mainVolSurfaceTable`}
      style={{ margin: "0px", padding: "0px" }}
    >
      <Row justify={"start"} style={{ width: "100%" }}>
        <div
          className={`${
            dark_Theme || false
              ? "pricer-ag-grid__container_dark"
              : "pricer-ag-grid__container"
          }`}
          style={{
            height: gridHeight,
            width: "85%",
            padding: "5px 0px 5px 10px",
          }}
        >
          <AgGridReact
            getRowId={getRowId}
            onCellEditingStopped={onCellEditingStopped}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            headerHeight={30}
            defaultColDef={{
              suppressMenu: true,
            }}
            rowHeight={30}
            rowData={rowsData}
            getRowStyle={(params) => {
              if (params.data.field !== "spread") {
                return {
                  borderTop: "none",
                  borderBottom: "none",
                };
              }
              getRowStyle(params);
            }}
            getRowClass={getRowClass}
            ref={gridRef}
            stopEditingWhenCellsLoseFocus={true}
            suppressMovableColumns={true}
            columnDefs={columnDefs}
            groupDefaultExpanded={1}
            suppressContextMenu
            className={`ag-theme-alpine pricer-ag-grid__table ${
              dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
            }`}
          />
        </div>
        <AgGridMonthEstimatesTable />
      </Row>
    </Col>
  );
}
