import axios from "axios";
import { GEEKS_USER } from "lib/constant/Profile/Profile";
import {
  getDataFromLocalStorage,
  localUserExist,
} from "lib/helpers/GeneralFuncHelpers";
import { toast } from "lib/helpers/utilities";
import * as Sentry from "@sentry/react";
// write a method to get token from local storage

// Common Axios Instance Config


const axiosConfig = {
  // baseURL: "http://localhost/aratus_backend_laravel/public/api", // Locals
  baseURL: process.env.REACT_APP_BASE_URL, // Development
};

// Create Default Axios Instance
const instance = axios.create(axiosConfig);

// Create Default Axios Instance for actions without Interceptors
const instanceWithoutInterceptors = axios.create(axiosConfig);

// set Common header for without authorization issue....
instanceWithoutInterceptors.interceptors.request.use((config) => {
  // Add common request header
  config.headers["Accept-Language"] = "en"; // Content Accept Language
  return config;
});

// Set ID-token as an authorization header for all request when its exists
instance.interceptors.request.use((config) => {
  // Get user info
  const userToken = getDataFromLocalStorage(GEEKS_USER)?.token;

  // If userInfo
  if (userToken) {
    config.headers["Authorization"] = `Bearer ${userToken}`; // fetch idToken
  }

  return config;
});

// Add interceptors for validate authorization
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      process.env.REACT_APP_SENTRY_DNS &&
      error?.response?.status === 500 &&
      (process.env.REACT_APP_NODE_ENV === "production" ||
        process.env.REACT_APP_NODE_ENV === "staging")
    ) {
      Sentry.captureException(error);
    }


    if (error?.response?.status === 422) {
      toast(error?.response?.data?.message, "warning");
    } else {
      toast(error?.response?.data?.message ?? "Backend Error", "error");
    }
    if (error.response.status === 401) {
      if (localUserExist()) {
        window.location.href = "/login";
        localStorage.clear();
      }
      return;
    }
    return Promise.reject(new Error(error));
  }
);

// Export Axios with Auth Headers
export default instance;

// Export Axios Without instance
export { instanceWithoutInterceptors as axiosWI };
