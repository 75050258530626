/* eslint-disable react-hooks/exhaustive-deps */
import * as Sentry from "@sentry/browser";
import { Feedback, getClient } from "@sentry/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Menu } from "antd";

import { NAVBAR_NAMES } from "lib/constant/Navbar";
import { useCallback, useContext } from "react";
import { GlobalContext } from "lib/contexts/GlobalContext";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";

function NavbarMenu({ onTabClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const client = getClient();
  const feedback = client?.getIntegration(Feedback);

  const {
    setLoadingOverlay,
    handleLogout,
    globalState: { loginInfo, dark_Theme },
  } = useContext(GlobalContext);
  const googleEvenTracker = useGAEvenetsTraker("event");

  const handleOnLogout = useCallback(() => {
    googleEvenTracker(GA_EVENTS_NAMES.LOGOUT, "user-logout", loginInfo?.name);

    setLoadingOverlay(true);
    setTimeout(() => {
      handleLogout();
      setLoadingOverlay(false);
    }, 1000);

    navigate("/login", { replace: true });
  }, []);

    const username = loginInfo?.name?.split(" ");

  return (
    <div
      key={"controls"}
      className="header__controls--control-item dropdown__container"
    >
      <div className="logout--menu">
        <Menu
          key={location.pathname}
          mode="horizontal"
          theme={dark_Theme || false ? "dark" : "light"}
          triggerSubMenuAction="click"
        >
          <Menu.SubMenu
            key="SubMenu-11"
            icon={
              <Avatar className="text-black">
                {username.map((name) => name[0].toUpperCase())}
              </Avatar>
            }
          >
            {(process.env.REACT_APP_NODE_ENV === "production" ||
              process.env.REACT_APP_NODE_ENV === "staging") && (
              <Menu.Item
                key="3"
                id="report-bug"
                onClick={() => {
                  Sentry.setUser({ email: loginInfo?.email });
                  feedback.openDialog();
                }}
              >
                {NAVBAR_NAMES.REPORT_BUG}
              </Menu.Item>
            )}

            <Menu.Item key="2" onClick={() => onTabClick(NAVBAR_NAMES.PROFILE)}>
              {NAVBAR_NAMES.PROFILE}
            </Menu.Item>
            <Menu.Item key="1" onClick={handleOnLogout}>
              {NAVBAR_NAMES.LOGOUT}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    </div>
  );
}

export default NavbarMenu;
