import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from "react";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";
import { ROUTES_PATH } from "routes";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "api/axios";
import { createBrowserHistory } from "history";
import { GEEKS_USER, LOCAL_STORAGE_INFO,  DARK_THEME } from "lib/constant/Profile/Profile";
import { getFilterButtons } from "api/AuditTrail/AuditTrail";
import { getColors } from "api/TeamRun";
import { TEAMRUN_STATE,TEAM_RUN_COLORS_STATE } from "lib/constant/TeamRun/TeamRun";
import { TEAMRUN_DATAfun } from "lib/helpers/TeamRun/utils";
import { getDataFromLocalStorage, setDataToLocalStorage } from "lib/helpers/GeneralFuncHelpers";


const customHistory = createBrowserHistory().location;

// List of initial state for all global data
export const initialState = {
  loadingOverlay: false,
  isUserLoggedIn: false,
  isUserLoggedOut: false,
  dark_Theme: true,
  filterButtons: [],
  auditTrailState:{
    columnState:null,
    selectedRows:[],
    selectedFilter:null,
    sortedModel:null,
  },
  loginInfo: "",
};

// Actions
const LOADING_OVERLAY        = "LOADING_OVERLAY";
const LOGIN_SUCCESS          = "LOGIN_SUCCESS";
const LOGIN_FAILED           = "LOGIN_FAILED";
const LOGOUT_SUCCESS         = "LOGOUT_SUCCESS";
const SET_AUDIT_TRAIL_COLUMN_STATE  = "SET_AUDIT_TRAIL_COLUMN_STATE";
const SET_AUDIT_TRAIL_SELECTED_ROW_STATE  = "SET_AUDIT_TRAIL_SELECTED_ROW_STATE";
const SET_AUDIT_TRAIL_SORTED_MODEL_STATE  = "SET_AUDIT_TRAIL_SORTED_MODEL_STATE";
const SET_AUDIT_TRAIL_SELECTED_FILTER = "SET_AUDIT_TRAIL_SELECTED_FILTER";
const DARK_THEME_CHECK       = "DARK_THEME_CHECK";
const SET_FILTER_BUTTONS = "SET_FILTER_BUTTONS";
const SET_AUDIT_TRAIL_STATE = "SET_AUDIT_TRAIL_STATE";

// Shared Reducer For Global Context
const Reducer = (globalState, action) => {
  switch (action.type) {
    case LOADING_OVERLAY:
      if (action.payload === globalState.loadingOverlay) return globalState;
      return {
        ...globalState,
        loadingOverlay: action.payload,
      };
    case LOGIN_SUCCESS :
      return {
        ...globalState,
        loginInfo: action.payload,
        isUserLoggedIn : true
      };
    case LOGIN_FAILED :
      return {
        ...globalState,
        isUserLoggedIn : false
      };
    case LOGOUT_SUCCESS:
      return {
        ...globalState,
        isUserLoggedIn : false
      };
    case SET_FILTER_BUTTONS:
      return {
        ...globalState,
        filterButtons: action.payload,
      }; 
    case SET_AUDIT_TRAIL_STATE:
      return {
        ...globalState,
        auditTrailState: action.payload
      }; 
    case SET_AUDIT_TRAIL_SELECTED_FILTER:
      return {
        ...globalState,
        auditTrailState:{
          columnState : globalState.auditTrailState.columnState,
          selectedRows: globalState.auditTrailState.selectedRows,
          selectedFilter: action.payload,
          sortedModel:globalState.auditTrailState.sortedModel
        }
      };
    case SET_AUDIT_TRAIL_COLUMN_STATE:
      return {
        ...globalState,
        auditTrailState:{
          columnState : action.payload,
          selectedRows: globalState.auditTrailState.selectedRows,
          selectedFilter: globalState.auditTrailState.selectedFilter,
          sortedModel:globalState.auditTrailState.sortedModel,
        }
      };
    case SET_AUDIT_TRAIL_SELECTED_ROW_STATE:
      return {
        ...globalState,
        auditTrailState:{
          columnState: globalState.auditTrailState.columnState,
          selectedRows : action.payload,
          selectedFilter: globalState.auditTrailState.selectedFilter,
          sortedModel:globalState.auditTrailState.sortedModel

        }
    };
    case SET_AUDIT_TRAIL_SORTED_MODEL_STATE:
      return {
        ...globalState,
        auditTrailState:{
          columnState: globalState.auditTrailState.columnState,
          selectedRows : globalState.auditTrailState.sortedModel,
          selectedFilter: globalState.auditTrailState.selectedFilter,
          sortedModel:action.payload, 
        }
    };
    case DARK_THEME_CHECK:
      return {
        ...globalState,
        dark_Theme : action.payload
      };
    default:
      return globalState;
  }
};

// Global State Which its provide context for children
const GlobalState = ({ children, defaultInitialState = {} }) => {
  const [globalState, dispatch] = useReducer(Reducer, { ...initialState, ...defaultInitialState });
  const navigate = useNavigate();
      const location = useLocation();
      const pathName = location.pathname;

  // Method used to toggle overlay OB/OFF
  const setLoadingOverlay = useCallback((value) => {
    dispatch({ type: LOADING_OVERLAY, payload: value });
  }, []);

  // Logout user
  const logoutCurrentUser = useCallback(() => {
    dispatch({ type: LOGOUT_SUCCESS });
  }, []);

  const setUserLoginInfo = useCallback((value) => {
    dispatch({ type: LOGIN_SUCCESS, payload: value });
  }, []);

  const setLoginFailed = useCallback((value) => {
    dispatch({ type: LOGIN_FAILED, payload: value });
  }, []);

  const setAuditTrailColumnState = useCallback((value) => {
    dispatch({ type: SET_AUDIT_TRAIL_COLUMN_STATE, payload: value });
  }, []);

  const setAuditTrailState = useCallback((value) => {
    dispatch({ type: SET_AUDIT_TRAIL_STATE, payload: value });
  }, []);

  const setAuditTrailSelectedRowState = useCallback((value) => {
    dispatch({ type: SET_AUDIT_TRAIL_SELECTED_ROW_STATE, payload: value });
  }, []);

  const setAuditTrailSortedModelState = useCallback((value) => {
    dispatch({ type: SET_AUDIT_TRAIL_SORTED_MODEL_STATE, payload: value });
  }, []);

  const setDark_Theme = useCallback((value) => {
    dispatch({ type: DARK_THEME_CHECK, payload: value });
  }, []);
  
  const setFilterButtons = useCallback((payload) => {
    dispatch({type: SET_FILTER_BUTTONS, payload});
  }, []);

  const setAuditTrailSelectedFilter = useCallback((payload) => {
    dispatch({type: SET_AUDIT_TRAIL_SELECTED_FILTER, payload});
  }, []);

  const handleLogout = useCallback(() => {
    // Clear related storage and more...
    localStorage.clear();
    logoutCurrentUser();
  }, []);


  /*
  * Navigate To Home Page If It's Login
  * */
  useEffect(() => {
    const geeksUser = getDataFromLocalStorage(GEEKS_USER);
   
    if(LOCAL_STORAGE_INFO.darktheme===null){
      setDataToLocalStorage(DARK_THEME,true)
      setDark_Theme(true)
    }else{
      setDark_Theme( LOCAL_STORAGE_INFO.darktheme)
    }
   

    
   

    if(pathName.startsWith(ROUTES_PATH.TRADE_CONFIRM.index)){
      const queryParams = new URLSearchParams(location.search);
      function createPathWithParams(path, token,trade_details) {
        return `${path}?token=${token}&trade_details=${trade_details}`;
      }   
  
         
    const pathWithParams = createPathWithParams(
      ROUTES_PATH.TRADE_CONFIRM.index,
      queryParams.get("token"),
      queryParams.get("trade_details")
    );
      return navigate(pathWithParams);
    }
    
    if(pathName.startsWith(ROUTES_PATH.RESET_PASSWORD.ResetPassword)){
      const queryParams = new URLSearchParams(location.search);
      function createPathWithParams(path, email, token) {
        return `${path}?email=${email}&token=${token}`;
      }   
  
         
    const pathWithParams = createPathWithParams(
      ROUTES_PATH.RESET_PASSWORD.ResetPassword,
      queryParams.get("email"),
      queryParams.get("token")
    );
      return navigate(pathWithParams);
    }
    
    if(geeksUser ){
      const parseData = getDataFromLocalStorage(GEEKS_USER)
      setUserLoginInfo(parseData);
      return customHistory.pathname !== ROUTES_PATH.SIGN_IN.index && navigate(customHistory.pathname, { replace: true });
    }

   
    return navigate(ROUTES_PATH.SIGN_IN.index, { replace: true });
  },[]);

  useEffect(()=>{
    if(globalState.isUserLoggedIn){
      getColors().then((res)=>{
        let data=res.data.data;
        let localStorageValue = TEAMRUN_DATAfun();
        let newData={...localStorageValue, [TEAM_RUN_COLORS_STATE]: data}
        setDataToLocalStorage(TEAMRUN_STATE,newData)
      }).catch((error)=>{
        console.log("Error while fetching Colors",error)
      })

      getFilterButtons().then((res)=>{
        setFilterButtons(res.data.data);
      }).catch((error)=>{
        console.log("Error while fetching Audit-trail filters")
      });

    }
  },[globalState.loginInfo])
  

  const contextValue = useMemo(() => {
    return {
      globalState,
      setLoadingOverlay,
      handleLogout,
      setUserLoginInfo,
      setLoginFailed,
      setAuditTrailColumnState,
      setAuditTrailSelectedRowState,
      setDark_Theme,
      setFilterButtons,
      setAuditTrailState,
      setAuditTrailSelectedFilter,
      setAuditTrailSortedModelState,
      isLocalStorageHasUserData : getDataFromLocalStorage(GEEKS_USER)
    };
  }, [globalState]);


  /**
   * If loading...
   */
  if (globalState.loadingOverlay) {
    return <PreLoadPagesSpin  dark_Theme={globalState.dark_Theme}/>;
  }

  return (
    <GlobalContext.Provider key={contextValue.globalState?.dark_Theme} value={ contextValue }>
      { children }
    </GlobalContext.Provider>
  );
};

// Create Global Context
export const GlobalContext = createContext(initialState);

// Export Global State Context Component
export default GlobalState;

