import { STATE_KEYS_NAME } from "lib/constant/AuditTrail/AuditTrail";
import { BACKEND_KEYS_NAME } from "lib/constant/EditTradeDetails/EditTradeDetails";
import { returnSelectedIdForEditTradeDetails } from "../AuditTrail/generalHelper";

export function prepareApiData(data, traderDetailsData) {
  let tradeLegsData = traderDetailsData;
  if (data) {
    const tradeValue = tradeLegsData[1]?.trader;
    const tradingCompanyValue = tradeLegsData[1]?.trading_company;
    const exchangeValue = tradeLegsData[1]?.exchange;
    if (data.hasOwnProperty(BACKEND_KEYS_NAME.LEGS)) {
      const deleteItem = data.legs.length;
      //Trader LEGS data with premium and quantity;
      let newLegsData = data[BACKEND_KEYS_NAME.LEGS]
        .map((item, index) => ({
          ...item,
          index,
          type: BACKEND_KEYS_NAME.LEGS, //Setting Type to diifferentiate inputs
          trader: tradeValue,
          trading_company: tradingCompanyValue,
          exchange: exchangeValue,
        }))
        .sort((a, b) => a.index - b.index);

      tradeLegsData.splice(1, deleteItem, ...newLegsData);
    }

    if (data.hasOwnProperty(BACKEND_KEYS_NAME.HEDGE)) {
      const index = tradeLegsData.length - 1;
      const tradeHedgeData = {
        hedges: data[BACKEND_KEYS_NAME.HEDGE],
        type: BACKEND_KEYS_NAME.HEDGE,
      };
      tradeLegsData.splice(index, 1, {
        ...tradeHedgeData,
        trader: tradeValue,
        trading_company: tradingCompanyValue,
        exchange: exchangeValue,
      });
    }
  }
  return { data, tradeLegsData };
}

export function prepareDataForGettingTradeDetails(data) {
  const exchangeId = data[BACKEND_KEYS_NAME.EXCHANGE_ID];
  const findActiveExChange = data[BACKEND_KEYS_NAME.EXCHANGES].find(
    (item) => item.id === exchangeId
  );

  //Trader LEGS data with premium and quantity;
  const tradeLegsData = data[BACKEND_KEYS_NAME.LEGS]
    .map((item, index) => ({
      ...item,
      index,
      type: BACKEND_KEYS_NAME.LEGS, //Setting Type to diifferentiate inputs
      //exchange: findActiveExChangeForSell?.[BACKEND_KEYS_NAME.TITLE],
    }))
    .sort((a, b) => a.index - b.index);

  //Trader HEDGE data
  const tradeHedgeData = {
    hedges: data[BACKEND_KEYS_NAME.HEDGE],
    type: BACKEND_KEYS_NAME.HEDGE,
  };

  //Trader first row data
  // if()
  const firstRowData = {
    [STATE_KEYS_NAME.TRADER]: data[BACKEND_KEYS_NAME.TRADER],
    [STATE_KEYS_NAME.COMPANY_TRADER]: data[BACKEND_KEYS_NAME.COMPANY_TRADER],
    [STATE_KEYS_NAME.EXCHANGE_ID]: findActiveExChange?.[BACKEND_KEYS_NAME.ID],
    exchange: findActiveExChange?.[BACKEND_KEYS_NAME.NAME],
    [BACKEND_KEYS_NAME.QUANTITY_T]: data[BACKEND_KEYS_NAME.QUANTITY_T],
    type: BACKEND_KEYS_NAME.QUANTITY_T,
  };
  //Pushing Hedge data to Legs Data
  tradeLegsData.push(tradeHedgeData);
  tradeLegsData.unshift(firstRowData);

  return { data, tradeLegsData };
}

export function prepareBodyForCopyingOrSaveAndSendButton(
  props,
  traderDetailsData,
  apiData,
  isSellActive
) {
  const prepareBody = {
    [BACKEND_KEYS_NAME.IS_SELL]: isSellActive ? 1 : 0,
    [BACKEND_KEYS_NAME.TEM_SUMMARY_ID]:
      apiData?.[BACKEND_KEYS_NAME.TEM_SUMMARY_ID],
    [BACKEND_KEYS_NAME.TEAM_RUN_OPTION_ID]:
      props.rowSelectedInfo?.[BACKEND_KEYS_NAME.ID],
    ...(!props.isTeamRun &&
      !props.isPricer && { audit_trail_id: +props.rowSelectedInfo.id }),
    ...((props.isTeamRun || props.isPricer) && {
      summary_id: +returnSelectedIdForEditTradeDetails(props).split("/")[1],
    }),
  };
  traderDetailsData.forEach((item) => {
    if (item.type === BACKEND_KEYS_NAME.QUANTITY_T) {
      prepareBody[BACKEND_KEYS_NAME.TRADER] = item[STATE_KEYS_NAME.TRADER];
      prepareBody[BACKEND_KEYS_NAME.COMPANY_TRADER] =
        item[STATE_KEYS_NAME.COMPANY_TRADER];
      prepareBody[BACKEND_KEYS_NAME.EXCHANGE_ID] =
        item[STATE_KEYS_NAME.EXCHANGE_ID];
      prepareBody[BACKEND_KEYS_NAME.QUANTITY_T] =
        item[STATE_KEYS_NAME.QTY_VALUES][BACKEND_KEYS_NAME.VALUE];
    }
    if (item.type === BACKEND_KEYS_NAME.HEDGE) {
      let hedges = [];
      item.hedges.forEach((hedge) => {
        hedges.push({
          id: hedge[BACKEND_KEYS_NAME.ID],
          delta_quantity_t: hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T].value,
          delta_t: hedge[BACKEND_KEYS_NAME.DELTA].value,
          sell_or_buy: hedge[BACKEND_KEYS_NAME.SELLE_OR_BUY]
        });
      });

      prepareBody["hedges"] = hedges;
      
    }
  });
  return prepareBody;
}
